import React from 'react'
import Layout from 'components/Layout'
import Head from 'next/head'
import Link from 'next/link'
import Image from 'next/image'
import { Button, Card, Label, Container, Header, Icon, Segment, Embed } from 'semantic-ui-react'

import styles from './index.module.css'

import items from 'data/projects.json'

const HomepageHeading = (
  <div className={styles.hero}>
    <Container text style={{ paddingBottom: '4em' }} className="hero-module">
      <Header
        as="h1"
        content="Срубы домов и бань от производителя"
        style={{
          fontWeight: 'bold',
          marginBottom: 0,
        }}
      />
      <Header
        as="h2"
        content="Современные инженерные решения."
        style={{
          fontWeight: 'normal',
        }}
      />
      <Button primary size="huge" as={'a'} href="#projects">
        Проекты
        <Icon name="arrow right" />
      </Button>
    </Container>
  </div>
)

const Homepage = () => {
  return (
    <>
      <Head>
        <title>Пиломатериалы и оцилиндрованное бревно от производителя - Аврора Хоумс</title>
        <meta
          property="og:title"
          content="Пиломатериалы и оцилиндрованное бревно от производителя - Аврора Хоумс"
        />
        <meta
          name="description"
          content="Производство и реализация оцилиндрованного бревна погонажем и домокомплектами. Доска обрезная, брус хвойных пород естественной влажности и камерной сушки."
        />
        <meta
          property="og:description"
          content="Производство и реализация оцилиндрованного бревна погонажем и домокомплектами. Доска обрезная, брус хвойных пород естественной влажности и камерной сушки."
        />
        <meta
          property="og:image"
          content={`https://aurorahomes.ru/_next/image?url=${encodeURIComponent(
            '/img/projects/5/plane/5.jpg'
          )}&w=640&q=75`}
        />
        <link rel="preload" as="image" href="/img/hero.webp" />
      </Head>
      <Layout heading={HomepageHeading}>
        <a id={'projects'} />
        <Segment style={{ padding: '2em 0em' }} vertical>
          <Container>
            <Card.Group itemsPerRow={3} stackable={true}>
              {items.map((item) => (
                <Link href={`/project/${item.id}`} key={item.id}>
                  <Card
                    href={`/project/${item.id}`}
                    image={
                      <Image
                        alt={item.title}
                        src={item.preview[0].src}
                        width={400}
                        height={Math.round(400 * (item.preview[0].height / item.preview[0].width))}
                        unoptimized
                        loading={item.id < 3 ? 'eager' : 'lazy'}
                        priority={item.id === 0}
                      />
                    }
                    // image={item.preview[0].src}
                    header={item.title}
                    description={
                      <span className="right floated" style={{ paddingTop: '1em' }}>
                        <Link href={`/project/${item.id}/gable`}>
                          <Label content="Двускатная кровля" />
                        </Link>
                        <Link href={`/project/${item.id}/flat`}>
                          <Label content="Плоская кровля" />
                        </Link>
                      </span>
                    }
                    meta={item.desc}
                    extra={
                      <>
                        <span className="right floated" style={{ color: 'rgba(0,0,0,.8)' }}>
                          <h3>{item.price}</h3>
                        </span>
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: 'rgba(0,0,0,.8)',
                          }}
                        >
                          {item.s} м²
                        </span>
                      </>
                    }
                  />
                </Link>
              ))}
            </Card.Group>
          </Container>
        </Segment>
        <Segment style={{ padding: '2em 0em' }} vertical secondary>
          <Container text style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as="h3" style={{ fontSize: '2em' }} textAlign="center">
              Аврора Хоумс
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Собственное производство, проектирование, строительство. Мы создаем современные бани и
              дома из оцилиндрованного бревна, контролируя качество на всех этапах. Aurora Homes -
              это современная технологичная компания, которая много лет проектирует и строит бани из
              экологически чистых материалов.
            </p>
          </Container>
        </Segment>
      </Layout>
    </>
  )
}

export default Homepage
